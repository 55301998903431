import React, { useEffect, useRef } from "react";
import HomeNewsAndMedia from "./HomeNewsAndMedia";
import HomeOfferings from "./HomeOfferings";
import HomeRegister from "./HomeRegister";
import HomeTrans from "./HomeTrans";
import HomeWallOfJustice from "./HomeWallOfJustice";
import PopularsHome from "./PopularsHome";
import SearchHome from "./SearchHome";
import TabsHome from "./TabsHome";
import "./home.css";

const Home = () => {


  return (
    <div
      className="p-0 m-0 g-0"
      style={{ height: "92vh", width: "100vw", overflowX: "hidden" , overflowY:"auto"  }}
    >
      <SearchHome data-scroll-section />
      <TabsHome data-scroll-section />
      <PopularsHome data-scroll-section />
      <HomeTrans data-scroll-section />
      <HomeRegister data-scroll-section />
      <HomeWallOfJustice data-scroll-section />
      <HomeOfferings data-scroll-section />
      <HomeNewsAndMedia data-scroll-section />
    </div>
  );
};

export default Home;
