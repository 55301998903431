import React, { useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import img12 from '../../../Assets/image/Rectangle 83.png';
import img13 from '../../../Assets/image/Rectangle 83.png';
import img2 from '../../../Assets/image/Rectangle 83.png';
import demoimg from '../../../Assets/image/Rectangle 14.png';
import firstIcon from '../../../Assets/image/FirstImage.png';
import second from '../../../Assets/image/second.png';
import third from '../../../Assets/image/Tax.png';
import { IoIosArrowForward } from "react-icons/io";

import '../../../App.css';
import HomeOfferings from '../Home/HomeOfferings';
import { getCountryFlagSrc } from '../../../Utils/getFlagUrl';

const HomeServices = () => {
 

    const carouselRef = useRef(null);

    const handleScroll = (e) => {
        const delta = Math.sign(e.deltaX);  
        const carousel = carouselRef.current;

        if (delta > 0) { 
            carousel.next(); 
        } else if (delta < 0) { 
            carousel.prev();  
        }
    };


    const data = [
        {
            hd:'Mohan Web Solutions'     
    },
        {
            hd:'Mohan Web Solutions'     
    },
        {
            hd:'Mohan Web Solutions'     
    },
        {
            hd:'Mohan Web Solutions'     
    },
        {
            hd:'Mohan Web Solutions'     
    },
        {
            hd:'Mohan Web Solutions'     
    },
     
]


const Business = [
  {
     soft:'Software Development',
     ser:'34 Service Providers',
     firstIcon:firstIcon,
},
  {
     soft:'Software Development',
     ser:'34 Service Providers',
     firstIcon:firstIcon,
},
  {
     soft:'Software Development',
     ser:'34 Service Providers',
     firstIcon:firstIcon,
},
  {
     soft:'Lawyer Services',
     ser:'34 Service Providers',
     firstIcon:second,
},
  {
     soft:'Lawyer Services',
     ser:'34 Service Providers',
     firstIcon:second,
},
  {
     soft:'Lawyer Services',
     ser:'34 Service Providers',
     firstIcon:second,
},
  {
     soft:'Lawyer Services',
     ser:'34 Service Providers',
     firstIcon:second,
},
  {
     soft:'Lawyer Services',
     ser:'34 Service Providers',
     firstIcon:second,
},
  {
     soft:'Lawyer Services',
     ser:'34 Service Providers',
     firstIcon:second,
},
  {
     soft:'Accounting and Taxation',
     ser:'34 Service Providers',
     firstIcon:third,
},
  {
     soft:'Accounting and Taxation',
     ser:'34 Service Providers',
     firstIcon:third,
},
  {
     soft:'Accounting and Taxation',
     ser:'34 Service Providers',
     firstIcon:third,
},
]

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>

                    <div className='col-lg-12 col-sm-12'>
                        <div onWheel={handleScroll}>
                            <Carousel
                                ref={carouselRef}
                                controls={false}
                                indicators={false}
                                pause={false}
                                // interval={2500}

                            >
                                <Carousel.Item>
                                    <img
                                        className="d-block custom-slide"
                                        src={img2}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block custom-slide"
                                        src={img12}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block custom-slide"
                                        src={img13}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
 
                </div>
  

                <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                        <strong>Your Browsing History</strong>
                        <button className='btn mt-0'  style={{fontWeight:'600',textDecoration:'underline'}}>View More</button>
                    </div>
                    </div>
                </div>
                
            </div>



            <div className='container py-2 mt-2' style={{backgroundColor:'#F9F9F9', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

    {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h5 style={{fontWeight:'700',  }}>{res.hd}</h5>
                  <h6 className='pb-0 mb-0' style={{color:'#5D5D5D',fontWeight:'700',  }}>Budget</h6>
                  <p className='pb-0 mb-0 pt-1' style={{fontSize:'15px'}}>₹ 1,45,000 - ₹ 2,75,000</p>
                  <p style={{fontSize:'14px', color:'#5D5D5D',}}>Sold From :<span style={{color:'#000'}}> India <img src={getCountryFlagSrc('IN')} alt="India Flag" className='ms-2' style={{width:'20px', height:'20px', borderRadius:'50%'}} /></span> </p>  
                </div>
              </div>
            )
        })
    } 
  </div>
</div>

                    </div>
                </div>
            </div>

            {/* Recommended For You */}

            <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                        <h5 style={{color:'#000', fontWeight:'600'}}>You Might Need</h5> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-2 mt-2' style={{backgroundColor:'#F9F9F9', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

  {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h5 style={{fontWeight:'700',  }}>{res.hd}</h5>
                  <h6 className='pb-0 mb-0' style={{color:'#5D5D5D',fontWeight:'700',  }}>Budget</h6>
                  <p className='pb-0 mb-0 pt-1' style={{fontSize:'15px'}}>₹ 1,45,000 - ₹ 2,75,000</p>
                  <p style={{fontSize:'14px', color:'#5D5D5D',}}>Sold From :<span style={{color:'#000'}}> India <img src={getCountryFlagSrc('IN')} alt="India Flag" className='ms-2' style={{width:'20px', height:'20px', borderRadius:'50%'}} /></span> </p>  
                </div>
              </div>
            )
        })
    } 
  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div>
  

   {/* for New Arrivals */}
 


   <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div  > 
                        <strong>New Arrivals</strong> <br/>
                        <span>100,000+ Products Added Today</span> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-3 mt-2' style={{backgroundColor:'#F9F9F9', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

  {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h5 style={{fontWeight:'700',  }}>{res.hd}</h5>
                  <h6 className='pb-0 mb-0' style={{color:'#5D5D5D',fontWeight:'700',  }}>Budget</h6>
                  <p className='pb-0 mb-0 pt-1' style={{fontSize:'15px'}}>₹ 1,45,000 - ₹ 2,75,000</p>
                  <p style={{fontSize:'14px', color:'#5D5D5D',}}>Sold From :<span style={{color:'#000'}}> India <img src={getCountryFlagSrc('IN')} alt="India Flag" className='ms-2' style={{width:'20px', height:'20px', borderRadius:'50%'}} /></span> </p>  
                </div>
              </div>
            )
        })
    } 

  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div> 

 {/* for Best Deal */}



 <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div  > 
                        <strong>Popular Services</strong> <br/>
                        <span>100,000+ Services Added Today</span> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-3 mt-2' style={{backgroundColor:'#FCF7F7', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

  {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h5 style={{fontWeight:'700',  }}>{res.hd}</h5>
                  <h6 className='pb-0 mb-0' style={{color:'#5D5D5D',fontWeight:'700',  }}>Budget</h6>
                  <p className='pb-0 mb-0 pt-1' style={{fontSize:'15px'}}>₹ 1,45,000 - ₹ 2,75,000</p>
                  <p style={{fontSize:'14px', color:'#5D5D5D',}}>Sold From :<span style={{color:'#000'}}> India <img src={getCountryFlagSrc('IN')} alt="India Flag" className='ms-2' style={{width:'20px', height:'20px', borderRadius:'50%'}} /></span> </p>  
                </div>
              </div>
            )
        })
    } 

  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div>


 {/* for Popular Products */} 

<div className='container-fluid my-5'>
    <div className='row'>
        <div className='col-lg-7 col-sm-12'>
             <h2 style={{fontWeight:'700'}}>Explore Amazing and Tailored Services according to your Business Needs</h2>
        </div>
    </div>
</div>



<div className='container   ' style={{paddingBottom:'100px'}}>
  <div className='row'>

  {
  Business?.map((res, index) => {
    return (
      <div className="col-lg-4 col-md-6 col-sm-12 mt-4 pt-2" key={index}>
        <div
          className="card p-0"
          style={{ border: '1px solid #BBBBBB33', verticalAlign: 'middle' }}
        >
          <div className="card-body py-0 py-1 ps-0 pe-3">
            <div className="row d-flex align-items-center">
              <div className="col-3">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={res.firstIcon} style={{ width: '80%' }} />
                </div>
              </div>

              <div className="col-8 mt-2">
                <div style={{ lineHeight: '10px', textAlign: 'left' }}>
                  <h5 style={{ fontSize: '18px' }}>{res.soft}</h5>
                  <p style={{ color: '#CF0014', fontWeight: '600', fontSize: '13px' }}>
                    {res.ser}
                  </p>
                </div>
              </div>

              <div className="col-1 ">
                <IoIosArrowForward className="ps-0" style={{ fontSize: '20px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
}

   
  </div>
</div>


        </div>
    );
}

export default HomeServices;
