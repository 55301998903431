import "./header.css"
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../../Assets/svg/indiicon.svg"
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Span from "../../Translation/Translation";
import { useState } from "react";
import { BsBag } from "react-icons/bs";
import { BiMessageDetail } from "react-icons/bi";
import Box from '@mui/material/Box';
import { FaRegUser } from "react-icons/fa6";
import Badge from '@mui/material/Badge';
import { getCountryFlagSrc } from "../../Utils/getFlagUrl";
import { useMediaQuery } from "react-responsive";
import { Menu, MenuItem, IconButton } from '@mui/material';
import { IoIosArrowDown } from "react-icons/io";

import { CiMenuKebab } from "react-icons/ci";
const Header = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [menu, setMenu] = useState(null);
    const handleClickMenu = (event) => {
        setMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenu(null);
    };


    const isMobile = useMediaQuery({
        query: "(max-device-width: 700px)",
    });


    return (
        <div className="head0 d-flex flex-column flex-md-row overflow-hidden" style={{ height: "8vh" }}>

            <div className="flex-grow-md-0 pt-2 pt-md-0 center-y justify-content-between justify-content-md-center  gap-3 ps-3">

                <div className="center-data">
                    <RxHamburgerMenu size={18} />
                </div>


                <div className="center-data">
                    <img src={logo} style={{ height: "25px" }}></img>
                </div>


                <div className="center-data gap-1">
                    <div className="cogr s14" >Deliver to IN</div>
                    <img style={{ borderRadius: "50%", height: "22px", width: "22px", opacity: 0.6 }} src={getCountryFlagSrc("IN")} alt="country" />
                </div>


                <div className="d-block d-md-none pe-3 ">
                    <IconButton onClick={handleClickMenu} style={{ backgroundColor: "#ffffff", border: 'none' }}>
                        <IoIosArrowDown  size={25} />
                    </IconButton>







                    <Menu
                        anchorEl={menu}
                        open={Boolean(menu)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: "bottom"
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                        }}
                    >


                        <MenuItem onClick={() => {
                            handleCloseMenu()
                            // handleShow1()
                        }}>

<button className='rout bgr1 rounded text-white center-data s14 py-1' style={{border:"2px solid #A70010" , width:"100px"}}>
                Sign In
                </button>

  
                        </MenuItem>

                        <MenuItem onClick={() => {
                            handleCloseMenu()
                            // handleShow1()
                        }}>



<button className='rout rounded cor1 py-1 center-data  s14' style={{border:"2px solid #A70010" , backgroundColor:"#ffffff" , width:"100px"}}>
                Register
                    </button>
                        </MenuItem>
                    </Menu>
                </div>
            </div>


            <div className="flex-grow-1 mt-2 mt-md-0  pb-0 mb-0 d-flex align-items-end" style={{ paddingLeft: isMobile ? 2 : "4rem" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "transparent", // Make the default indicator transparent
                        },
                    }}

                >
                    <Tab
                        label="Products"
                        sx={{
                            fontSize: "12px", // Default font size
                            color: "inherit", // Default color
                            "&.Mui-selected": {
                                fontSize: "13px", // Font size when selected
                                color: "#000000", // Black color when selected
                                borderBottom: "3px solid #F0B137", // Orange border-bottom when selected
                                fontWeight: 550
                            },
                        }}
                    />
                    <Tab
                        label="Services"
                        sx={{
                            fontSize: "12px",
                            color: "inherit",
                            "&.Mui-selected": {
                                fontSize: "13px",
                                color: "#000000",
                                borderBottom: "3px solid #F0B137", // Orange border-bottom when selected, 
                                fontWeight: 550

                            },
                        }}
                    />
                    <Tab
                        label="Manufacturers"
                        sx={{
                            fontSize: "12px",
                            color: "inherit",
                            "&.Mui-selected": {
                                fontSize: "13px",
                                color: "#000000",
                                borderBottom: "3px solid #F0B137" // Orange border-bottom when selected
                                , fontWeight: 550

                            },
                        }}
                    />
                    <Tab
                        label="SourceByRegion"
                        sx={{
                            fontSize: "12px",
                            color: "inherit",
                            "&.Mui-selected": {
                                fontSize: "13px",
                                color: "#000000",
                                borderBottom: "3px solid #F0B137", // Orange border-bottom when selected
                                fontWeight: 550

                            },
                        }}
                    />
                </Tabs>




            </div>


            <div className="flex-grow-0 gap-4 pe-4 justify-content-start align-items-center d-none d-md-flex" >


                <div className="center-data gap-1">
                    <div className="cogr s14" >Deliver to IN</div>
                    <img style={{ borderRadius: "50%", height: "22px", width: "22px", opacity: 0.6 }} src={getCountryFlagSrc("IN")} alt="country" />
                </div>



                <button className='rout bgr1 rounded text-white p-1 px-4 s14' style={{border:"2px solid #A70010"}}>
                Sign In
                </button>

                <button className='rout rounded cor1 p-1 px-4  s14' style={{border:"2px solid #A70010" , backgroundColor:"#ffffff"}}>
                Register
                    </button>



            </div>


        </div>
    )
}

export default Header