import React from 'react'
import drone from "../../../Assets/image/purchase.png"
import tangleLine from "../../../Assets/image/homeline.png"
import { useMediaQuery } from 'react-responsive';
import { GoVerified } from "react-icons/go";
import { IoShieldCheckmarkOutline } from "react-icons/io5";


const HomeRegister = () => {
    const isMobile = useMediaQuery({
        query: "(max-device-width: 700px)",
    });


    return (
        <>
        <div style={{width: "100vw" , position:"relative"}}>
        <div className='py-2 py-md-4 p-0 m-0 mt-3 g-0 container-fluid px-3 px-md-5 w7'>

            <div className='row ' style={{
                background: "linear-gradient(90deg, #FFFCF5 0%, #FFF2CF 100%)",
                borderRadius: "20px",
            }}>


                <div className='col-12 col-md-7 center-y' style={{
                    borderRadius: isMobile ? "20px" : "20px 0px 0px 20px",
                }} >


                    <div className='d-flex justify-content-around flex-column h-100 py-3 py-md-5'>
                        <div className='headline2 w7 mb-2 mb-md-0 text-center text-md-start'>
                            Purchase To Payments<br />Protection with Indibaba
                        </div>

                        <div className='between-x gap-3'>
                            <div className='center-y flex-column flex-md-row gap-1 gap-md-2'>
                                <div className='center-data'>
                                    <GoVerified size={25} color='#000000' />

                                </div>
                                <div className='s14 w4 text-center text-md-start'>
                                    100% Quality Assurance <br /> with Verified Sellers
                                </div>
                            </div>

                            <div className='center-y flex-column flex-md-row gap-1 gap-md-2 ps-3 ' style={{borderLeft:"2px solid gray"}}>
                                <div className='center-data'>
                                    <GoVerified size={25} color='#000000' />

                                </div>
                                <div className='s14 w4 text-center text-md-start'>
                                100% Secured Payments <br />with Trade Assurance
                                </div>
                            </div>


                        </div>

                        <div className='d-flex justify-content-center  justify-content-md-start mt-2 mt-md-0'>
                            <button style={{
                                outline:0,
                                border:"2px solid black",
                                padding:"10px",
                                backgroundColor:"#ffffff",
                                borderRadius:"30px"
                            }} className='w6 s15'>

                                Know more
                            </button>
                        </div>

                    </div>


                </div>



                <div className='d-none d-md-block col-md-5'
                    style={{
                        backgroundImage: `url(${drone})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "300px",
                        borderRadius: "0px 20px 20px 0px"
                    }}
                >


                </div>


            </div>


            <div className='row'>
                <div className='col-12 center-data flex-column gap-4 py-5'>
                 
                    <div className='fs-4 fs-md-3  w5'>
                    <div className=' text-center'>
                    Get Tailored Discounts & Offers
                    </div>
                    <div className=' text-center'>
                    according to your Business Needs
                    </div>
                    </div>

                    <button className='rout bgr1 rounded text-white py-2 px-5 border-0 s14' style={{}}>
                    Register Now
                    </button>

                    
                </div>
            </div>


        </div>
            <img style={{position:"absolute" , width:"100%" , top:"85%" , zIndex:-1}} src={tangleLine}></img>
        </div>
            </>
    )
}

export default HomeRegister