import React, { useState } from 'react'
import Electronics from "../../../Assets/image/anc.png"
import { FaCheck } from 'react-icons/fa6'



const data =[
    {
      id : 1 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
      id : 2,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 3,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 4 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
      id : 5 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
      id : 6,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 7,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 8 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 9 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
    {
          id : 10 ,
    image :Electronics,
    des:'Smart Devices/ IOT Devices'
  },
  ]


const HomeOfferings = () => {

    
    return (
        <div className='py-2 py-md-4 p-0 mx-0 mt-3 g-0 container-fluid px-3 px-md-5 w7'>

            <div className='row '>
                <div className='col-12 col-md-10 ' >

                    <h2 >
                        Explore Amazing and Tailored Offerings
                        <br className='d-none d-md-block' />
                        according to your Business Needs
                    </h2>

                </div>

                <div className='col-12 col-md-10'>
                    <div className='container'>
                        <div className='row'>
                    {
                        data.map((res) => {
                            return (
                                <>



                                    <div className=' col-4 col-md-1  px-2' >

                                      <div className='offerings' style={{cursor:"pointer"}}>
                                        <div className=' w-100 py-2 mt-3 maindiv1'>
                                            <div className=''>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img src={res.image} alt='img' className='p-1' style={{ width: '70%' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <h6 className='mt-2 belowtext' style={{ fontSize: '12px' }}>{res.des}</h6>
                                    </div>


                                    </div>
                                </>
                            )
                        })
                    }
</div>
</div>


                </div>
            </div>



        </div>
    )
}

export default HomeOfferings