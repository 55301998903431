import React from 'react'
import { CiSearch } from "react-icons/ci";

const SearchHome = () => {
  return (
    <div className='border-bottom py-2 py-md-4 p-0 m-0 g-0 container-fluid px-2 px-md-5 w7' style={{ width: "100vw" }}>

      <div className='row'>
        <div className='col-12 col-md-6 headline1' >
          Your Gateway To Global <br /> Trade is Here
        </div>



        <div className='col-12 col-md-6 s13 w6 center-y'>

          <div className='px-3 mt-1 mt-md-0 rounded d-flex center-y gap-2 flex-grow-1' style={{ border: "1px solid #000000", paddingBlock: "11px" }}>
            <div className='cogr1 '>Search for</div>


            <div class="search-carousel">
              <div class="carousel-inner">
                <div>Watch </div>
                <div>Smartphone </div>
                <div>Laptop </div>
              </div>
            </div>




            <div className='flex-grow-1'></div>
            <div className='center-data'>
              <CiSearch size={20} />
            </div>
          </div>



        </div>
      </div>

    </div>
  )
}

export default SearchHome