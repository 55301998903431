import React from 'react'

const HomeWallOfJustice = () => {
    return (
        <div className='py-2 py-md-5 p-0 aboveLine g-0 container-fluid px-3 px-md-5 w7 ' style={{ width: "100vw"}}>


            <div className='row ' >


                <div className='col-12 col-md-6 p-2' >

                    <div className='d-flex flex-column gap-3'>

                        <h1>Wall of Justice</h1>

                        <h6 className='cogr2 s18'>Watch about the experience of our <br /> millions of Users</h6>


                        <div>
                        <div className='container' style={{ height: "100px" }}>
                            <div className='row h-100'>


                                <div className='col-6 col-md-4 d-flex align-items-center py-2' style={{ borderLeft: "4px solid #F0B137" }}>

                                    <div>
                                        <h2 className='mb-0 '>42K+</h2>
                                        <h6 className='cogr2 s18  '>Products</h6>
                                    </div>

                                </div>


                                <div className='col-6 col-md-4 d-flex align-items-center py-2' style={{ borderLeft: "4px solid #F0B137" }}>

                                    <div>
                                        <h2 className='mb-0 '>2500+</h2>
                                        <h6 className='cogr2 s18 '>Sellers</h6>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className='container' style={{ height: "100px" }}>
                            <div className='row h-100 mt-4'>


                                <div className='col-6 col-md-4 d-flex align-items-center py-2' style={{ borderLeft: "4px solid #F0B137" }}>

                                    <div>
                                        <h2 className='mb-0 '>250+</h2>
                                        <h6 className='cogr2 s18  '>Product Categories</h6>
                                    </div>

                                </div>


                                <div className='col-6 col-md-4 d-flex align-items-center py-2' style={{ borderLeft: "4px solid #F0B137" }}>

                                    <div>
                                        <h2 className='mb-0 '>190+</h2>
                                        <h6 className='cogr2 s18 '>Countries & Regions</h6>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </div>

                    </div>

                </div>




                <div className='col-12 col-md-6 p-2 pe-0 pe-md-5' >

                    <div className='d-flex flex-column gap-3'>


                    </div>

                </div>





        
            </div>

        </div>
    )
}

export default HomeWallOfJustice