import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import ViewMoreCard from './ViewMoreCard';
import ProductCard from './ProductCard';



const PopularsHome = () => {
    return (
        <div className='border-bottom py-2 pt-3 m-0 g-0 container-fluid px-3 px-md-5 ' style={{ width: "100vw" }}>
            <div className='row justify-content-around'>


                <div className='col-12 col-md-3 p-2'>
                    <div className='p-2 p-md-3' style={{ backgroundColor: "#F7F8FC", borderRadius: "15px" }}>


                        <div className='between-x'>

                            <div>
                                <div className='s15 w7'>New Arrivals</div>
                                <div className='s10 w5'>100,000+ Products Added Today</div>
                            </div>

                            <div className='center-data'>
                                <IoIosArrowForward size={20} />
                            </div>

                        </div>


                        <div className='container'>
                            <div className='row'>
                                {
                                    Array.from({ length: 3 }).map((_, index) => {
                                        return (
                                            <div key={index} className='col-6 p-2 d-flex justify-content-end'>
                                                <ProductCard />
                                            </div>
                                        )
                                    })
                                }


                                <div className='col-6 p-2 d-flex justify-content-start'>
                                    <ViewMoreCard />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>



                <div className='col-12 col-md-3 p-2'>
                    <div className='p-2 p-md-3' style={{ backgroundColor: "#FBFCF7", borderRadius: "15px" }}>


                        <div className='between-x'>

                            <div>
                                <div className='s15 w7'>Popular Products</div>
                                <div className='s10 w5'>100,000+ Products Added Today</div>
                            </div>

                            <div className='center-data'>
                                <IoIosArrowForward size={20} />
                            </div>

                        </div>


                        <div className='container'>
                        <div className='row'>
                                {
                                    Array.from({ length: 3 }).map((_, index) => {
                                        return (
                                            <div key={index} className='col-6 p-2 d-flex justify-content-end'>
                                                <ProductCard />
                                            </div>
                                        )
                                    })
                                }


                                <div className='col-6 p-2 d-flex justify-content-start'>
                                    <ViewMoreCard />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                <div className='col-12 col-md-3 p-2'>
                    <div className='p-2 p-md-3' style={{ backgroundColor: "#FCF7F7", borderRadius: "15px" }}>


                        <div className='between-x'>

                            <div>
                                <div className='s15 w7'>Best Deals</div>
                                <div className='s10 w5'>100,000+ Products Added Today</div>
                            </div>

                            <div className='center-data'>
                                <IoIosArrowForward size={20} />
                            </div>

                        </div>


                        <div className='container'>
                        <div className='row'>
                                {
                                    Array.from({ length: 3 }).map((_, index) => {
                                        return (
                                            <div key={index} className='col-6 p-2 d-flex justify-content-end'>
                                                <ProductCard />
                                            </div>
                                        )
                                    })
                                }


                                <div className='col-6 p-2 d-flex justify-content-start'>
                                    <ViewMoreCard />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}





export default PopularsHome