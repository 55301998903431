import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CiSearch } from "react-icons/ci";
import '../../../App.css'
import { RxDashboard } from "react-icons/rx";
import { PiMedalThin } from "react-icons/pi";
import TabsHome from '../Home/TabsHome';
import HomeSigninDataTab from './HomeSigninDataTab';
import HomeServices from './HomeServices';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const HomeSignin = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ height: '100vh', overflowY: 'scroll' }}>
    <div className='BgImage ' >
      <div className='container '>
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12 m-auto'>
            <div >
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"   style={{borderBottom:'1px solid #FFFFFF40', width:'75%'}} className='ABD'>
              <Tab label="Products"  style={{textTransform:'none', color:'#fff'}}/>
              <Tab label="Services"  style={{textTransform:'none',color:'#fff',}}/>
              <Tab label="Manufacturers" style={{textTransform:'none',color:'#fff'}} />
              <Tab label="Source By Region"  style={{textTransform:'none',color:'#fff'}}/>
            </Tabs> 
            </div>
 
          </div> 
        </div>

         <div className='row'>
          <div className='col-lg-2 col-sm-12  '></div>
          <div className='col-lg-6 col-sm-12  '>

          <div className="input-group my-4" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
  <input
    type="text"
    className="form-control custom-input"
    placeholder="Search for"
    aria-label="Username"
    aria-describedby="basic-addon1"
    style={{
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderRight: 'none',
      color: '#fff', borderRadius:'20px 0px 0px 20px', 
    }}
  />
  <span className="input-group-text" id="basic-addon1" style={{ backgroundColor: 'transparent', borderLeft: 'none' ,  borderRadius:'0px 20px 20px 0px'}}>
    <CiSearch className='text-white' />
  </span>
</div>



              <div style={{display:'flex', textWrap:'nowrap', alignItems:'center'}}>
                <span className='pe-3' style={{fontSize:'14px'}}>Recent Searches</span>
                <div className='Demo' style={{display:'flex', justifyContent:'space-between',  width:'100%', overflowX:'scroll'}}>

                  <button className='btn ms-2 text-white'  style={{border:'1px solid #fff', borderRadius:'20px', fontSize:'14px'}}>Mens T-shirt</button>
                  <button className='btn ms-2 text-white' style={{border:'1px solid #fff', borderRadius:'20px',fontSize:'14px'}}>Samsung Galaxy s24 Ultra</button>
                  <button className='btn ms-2 text-white' style={{border:'1px solid #fff', borderRadius:'20px',fontSize:'14px'}}>Samsung Galaxy s24 Ultra</button>
                  <button className='btn ms-2 text-white' style={{border:'1px solid #fff', borderRadius:'20px',fontSize:'14px'}}>Samsung Galaxy s24 Ultra</button>
                </div>
                </div>


          </div>
         </div>
      </div>
      </div>


      <section>
        <div className='container mt-4'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
              <h6>Welcome To Indibaba, Harsh</h6>
            </div>

              <div className='col-lg-8 col-md-12 col-sm-12'>
                <div className='row'> 

                   <TabsHome/>
                  
                </div>  
              </div>

          </div>
        </div>
      </section>


      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12'> 
            <TabPanel value={value} index={0}>
              <HomeSigninDataTab/>
             </TabPanel>
            <TabPanel value={value} index={1}>
              <HomeServices/>
            </TabPanel>
            <TabPanel value={value} index={2}>
              Tab 3 Content
            </TabPanel>
            <TabPanel value={value} index={3}>
              Tab 4 Content
            </TabPanel>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeSignin;
