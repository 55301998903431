import React from 'react'
import { Img } from 'react-image';
import drone from "../../../Assets/image/drone.png"
import { Skeleton } from '@mui/material';

const ProductCard = () => {

    return (
        <div className='text-nowrap s12 w6' style={{ width: "150px", overflowX: "hidden" }}>

            <Img
                loader={<Skeleton variant="square" />}
                src={drone}

                unloader={<div style={{
                    height: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f0f0"
                }}>
                    <p>Image failed</p>
                </div>}
                style={{ height: "150px", objectFit: "contain", borderRadius: "5px", width: "100%" }}
            />

            <div className='s16 w6 text-truncate '>HD Quality RC Drone</div>
            <div className='py-0 my-0 gy-0 text-truncate'>
                <span>₹ 450.00 - ₹ 550.00</span>
                <span>/Piece</span>
            </div>
            <div className='py-0 my-0 gy-0 text-truncate'>Min Qty : 20 Piece</div>


        </div>
    )
}


export default ProductCard