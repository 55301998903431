import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { store } from './Redux/Store/Store';
import MyProvider from './Context/MyProvider';
import { Toaster } from 'sonner';
import { BrowserRouter } from 'react-router-dom'


const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
  <QueryClientProvider client={queryClient}>
    <MyProvider>

      <div className='p-0 m-0 g-0' style={{height:"100vh" , width:"100vw" , overflow:"hidden"}}>
        <Toaster expand={false} position='top-right' className='me-2' richColors duration={2000} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </div>


    </MyProvider>
  </QueryClientProvider>
</Provider>
);


reportWebVitals();
