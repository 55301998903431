import "./App.css"
import "./fontsize.css"
import "./global.css"
import {  Routes , Route} from 'react-router-dom'
import Home from "./Screen/Private/Home/Home"
import Header from "./Components/Header/Header" 
import HomeSignin from "./Screen/Private/HomeSigninCpmponent/HomeSignin"


const App = () => {
  return (
    <>
    <Header/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Homesigin" element={<HomeSignin />} />
    </Routes>
    </>
  )
}

export default App;