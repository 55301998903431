import { useMediaQuery } from "react-responsive";
import news1 from "../../../Assets/image/news1.png"
import news2 from "../../../Assets/image/news2.png"
import news3 from "../../../Assets/image/news3.png"
import { IoCalendarOutline } from "react-icons/io5";


const HomeNewsAndMedia = () => {
    const isMobile = useMediaQuery({
        query: "(max-device-width: 700px)",
    });

    return (
        <div className='py-2 py-md-4 p-0 mx-0 mt-3 g-0 container-fluid px-3 px-md-5 w7'>

            <div className='row '>
                <div className='col-12 col-md-10 ' >

                    <h2 >
                        News & Media
                    </h2>

                </div>


                <div class="image-container col-12 col-md-5">
                    <img src={news1} alt="Sample Image" />
                    <div class="overlay-text text-white" style={{textWrap: isMobile ? "wrap" : "nowrap"}} >

                        <div className="w7 " style={{ fontSize: "24px" }}>Revenue of Tothenm lmtd stores <br />250% amid listing on Indibaba</div>
                        <div className="w5 s13 my-2">America based drug manufacturing firm confirms in<br />uptrends about their latest uptrends...</div>

                        <div className="center-y gap-3 s16 w4 mt-3 pb-1">
                            <div className="center-data">
                                <IoCalendarOutline size={25} />
                            </div>
                            18/09/2024
                        </div>

                    </div>
                </div>






                <div className='col-12 col-md-7 between-x flex-column mt-4 mt-md-0' style={{ height: "600px" }}>

                    <div class="image-container1 " style={{ height: "250px", width: "100%" }}>
                        <img src={news2} alt="broken link news2"></img>
                        <div class="overlay-text1 text-white"  style={{textWrap: isMobile ? "wrap" : "nowrap"}}>

                            <div className="w7 " style={{ fontSize: isMobile ? "20px" : "24px" }}>Revenue of Tothenm lmtd stores <br />250% amid listing on Indibaba</div>
                            <div className="w5 s13 my-2">America based drug manufacturing firm confirms in<br />uptrends about their latest uptrends...</div>

                            <div className="center-y justify-content-end gap-3 s16 w4 mt-3 pb-1">
                                <div className="center-data">
                                    <IoCalendarOutline size={25} />
                                </div>
                                18/09/2024
                            </div>

                        </div>
                    </div>


                    <div class="image-container1 " style={{ height: "250px", width: "100%" }}>
                        <img src={news3} alt="broken link news2"></img>
                        <div class="overlay-text1 text-white" style={{textWrap: isMobile ? "wrap" : "nowrap"}}>

                            <div className="w7 "  style={{ fontSize: isMobile ? "20px" : "24px" }}>Revenue of Tothenm lmtd stores <br />250% amid listing on Indibaba</div>
                            <div className="w5 s13 my-2">America based drug manufacturing firm confirms in<br />uptrends about their latest uptrends...</div>

                            <div className="center-y justify-content-end gap-3 s16 w4 mt-3 pb-1">
                                <div className="center-data">
                                    <IoCalendarOutline size={25} />
                                </div>
                                18/09/2024
                            </div>

                        </div>
                    </div>





                    <button style={{
                        outline: 0,
                        border: "2px solid black",
                        padding: "10px",
                        backgroundColor: "#ffffff",
                        borderRadius: "30px",
                        width: "fit-content",
                    }} className='w6 s15 px-4 mb-3'>

                        More
                    </button>

                </div>





            </div>



        </div>
    )
}

export default HomeNewsAndMedia