import React from 'react'
import { RxDashboard } from "react-icons/rx";
import dashIcon from "../../../Assets/svg/dashboardY.svg"
import memberIcon  from "../../../Assets/svg/membership.svg"
import quotationIcon from "../../../Assets/svg/quotation.svg"
import logisticsIcon from "../../../Assets/svg/logistics.svg"

const TabsHome = () => {
  return (
    <div className='d-flex justify-content-start justify-content-md-center gap-3 py-2 text-nowrap px-2 px-md-0' style={{overflowX:"auto"}}>

    <div className='p-2 center-data gap-1 rounded' style={{backgroundColor:"#FFF2CF"}}>

        <div className='center-data'>
        <img src={dashIcon}></img>
        </div>
        <div className='s15 w7'>All Categories</div>

    </div>

    <div className='p-2 center-data gap-1 rounded' style={{backgroundColor:"#E5EBFF"}}>

        <div className='center-data'>
        <img src={memberIcon}></img>
        </div>
        <div className='s15 w7'>Indibaba Memberships</div>

    </div>

    <div className='p-2 center-data gap-1 rounded' style={{backgroundColor:"#F5E5FF"}}>

        <div className='center-data'>
        <img src={quotationIcon}></img>
        </div>
        <div className='s15 w7'>Request For Quotation</div>

    </div>

    <div className='p-2 center-data gap-1 rounded' style={{backgroundColor:"#FFE5E5"}}>

        <div className='center-data'>
        <img src={logisticsIcon}></img>
        </div>
        <div className='s15 w7'>Logistics Services</div>

    </div>

    </div>
  )
}

export default TabsHome