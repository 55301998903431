import React, { useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import img12 from '../../../Assets/image/Rectangle 83.png';
import img13 from '../../../Assets/image/Rectangle 83.png';
import img2 from '../../../Assets/image/Rectangle 83.png';
import demoimg from '../../../Assets/image/Rectangle 7.png';

import '../../../App.css';
import HomeOfferings from '../Home/HomeOfferings';

const HomeSigninDataTab = () => {
 

    const carouselRef = useRef(null);

    const handleScroll = (e) => {
        const delta = Math.sign(e.deltaX);  
        const carousel = carouselRef.current;

        if (delta > 0) { 
            carousel.next(); 
        } else if (delta < 0) { 
            carousel.prev();  
        }
    };


    const data = [
        {
            hd:'HD Quality RC Drone'     
    },
        {
            hd:'HD Quality RC Drone'     
    },
        {
            hd:'HD Quality RC Drone'     
    },
        {
            hd:'HD Quality RC Drone'     
    },
        {
            hd:'HD Quality RC Drone'     
    },
]

    return (
        <div>
            <div className='container-fluid'>
                <div className='row'>

                    <div className='col-lg-12 col-sm-12'>
                        <div onWheel={handleScroll}>
                            <Carousel
                                ref={carouselRef}
                                controls={false}
                                indicators={false}
                                pause={false}
                                // interval={2500}

                            >
                                <Carousel.Item>
                                    <img
                                        className="d-block custom-slide"
                                        src={img2}
                                        alt="First slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block custom-slide"
                                        src={img12}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block custom-slide"
                                        src={img13}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
 
                </div>
  

                <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                        <strong>Your Browsing History</strong>
                        <button className='btn mt-0'  style={{fontWeight:'600',textDecoration:'underline'}}>View More</button>
                    </div>
                    </div>
                </div>
                
            </div>



            <div className='container py-2 mt-2' style={{backgroundColor:'#F9F9F9', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

    {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h6 style={{fontWeight:'600', fontSize:'15px'}}>{res.hd}</h6>
                  <p className='pb-0 mb-0' style={{fontSize:'14px'}}>₹ 450.00 - ₹ 550.00/Piece</p>
                  <p style={{fontSize:'14px'}}>Min Qty : 20 Piece</p>  
                </div>
              </div>
            )
        })
    } 
  </div>
</div>

                    </div>
                </div>
            </div>

            {/* Recommended For You */}

            <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                        <strong>Recommended For You</strong> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-2 mt-2' style={{backgroundColor:'#F9F9F9', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

    {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h6 style={{fontWeight:'600', fontSize:'15px'}}>{res.hd}</h6>
                  <p className='pb-0 mb-0' style={{fontSize:'14px'}}>₹ 450.00 - ₹ 550.00/Piece</p>
                  <p style={{fontSize:'14px'}}>Min Qty : 20 Piece</p>  
                </div>
              </div>
            )
        })
    }

  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div>




   {/* for New Arrivals */}



   <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div  > 
                        <strong>New Arrivals</strong> <br/>
                        <span>100,000+ Products Added Today</span> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-3 mt-2' style={{backgroundColor:'#F9F9F9', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

    {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h6 style={{fontWeight:'600', fontSize:'15px'}}>{res.hd}</h6>
                  <p className='pb-0 mb-0' style={{fontSize:'14px'}}>₹ 450.00 - ₹ 550.00/Piece</p>
                  <p style={{fontSize:'14px'}}>Min Qty : 20 Piece</p>  
                </div>
              </div>
            )
        })
    }

  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div>





 {/* for Best Deal */}



 <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div  > 
                        <strong>Best Deals</strong> <br/>
                        <span>100,000+ Products Added Today</span> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-3 mt-2' style={{backgroundColor:'#FCF7F7', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

    {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h6 style={{fontWeight:'600', fontSize:'15px'}}>{res.hd}</h6>
                  <p className='pb-0 mb-0' style={{fontSize:'14px'}}>₹ 450.00 - ₹ 550.00/Piece</p>
                  <p style={{fontSize:'14px'}}>Min Qty : 20 Piece</p>  
                </div>
              </div>
            )
        })
    }

  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div>


 {/* for Popular Products */}



 <div className='container-fluid   hello' style={{paddingTop:'20px'}}>
            <div className='row'>
                    <div className='col-lg-12 col-sm-12 mt-4'>
                        <div  > 
                        <strong>Popular Products</strong> <br/>
                        <span>100,000+ Products Added Today</span> 
                    </div>
                    </div>
                </div>
            </div>


            <div className='container-fluid py-3 mt-2' style={{backgroundColor:'#FCF7F7', borderRadius:'20px'}}>
                <div className='row'>
                    <div className='col-lg-12'>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'scroll', width: '100%' }}>
  <div style={{ display: 'flex', width: 'fit-content' }}>
    

    {
        data.map((res,ind)=>{
            return(
                <div className='card border-0' style={{ minWidth: '25%', margin: '0 10px' }}>
                <div className='card-header border-0'>
                  <img src={demoimg} style={{ width: '100%' }} alt="Drone" />
                </div>
                <div className='card-body' style={{ backgroundColor: '#F9F9F9' }}>
                  <h6 style={{fontWeight:'600', fontSize:'15px'}}>{res.hd}</h6>
                  <p className='pb-0 mb-0' style={{fontSize:'14px'}}>₹ 450.00 - ₹ 550.00/Piece</p>
                  <p style={{fontSize:'14px'}}>Min Qty : 20 Piece</p>  
                </div>
              </div>
            )
        })
    }

  
 

    {/* Additional cards can be added similarly */}
  </div>
</div>

                    </div>
                </div>
            </div>




<div className='container-fluid'>
    <div className='row'>
        <div className='col-lg-12 col-sm-12'>
            <HomeOfferings/>
        </div>
    </div>
</div>


        </div>
    );
}

export default HomeSigninDataTab;
