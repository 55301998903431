import React from 'react'
import choco from "../../../Assets/image/choco.png"
import { MdOutlineAddBusiness } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { useMediaQuery } from 'react-responsive'

const HomeTrans = () => {
    const isMobile = useMediaQuery({
        query: "(max-device-width: 700px)",
      });
  return (
    <div
      className='d-flex flex-column flex-md-row py-3 py-md-5 justify-content-start justify-content-md-center align-items-center gap-3 text-nowrap px-3 px-md-0'
      style={{  overflowX: "auto",
        backgroundImage: `url(${choco})`,  
        backgroundSize:"cover",         
        backgroundRepeat: "no-repeat",  
        backgroundPosition: "center",   
        overflow:"hidden"   
      }}> 

    <div 
        className='d-flex flex-md-column flex-row gap-2 align-items-center align-items-md-start'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",  
            backdropFilter: "blur(3px)",             
            border: "none", 
            borderRadius: "15px",                        
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",  
            height: "fit-content",
            padding: "20px",
            width:isMobile && "100%"

          }}
    >

      <MdOutlineAddBusiness size={60} color='#ffffff'/>
      <div className='s16 w4 text-white '>
      Millions of <br/> Businesses Offering
      </div>

    </div>
    



     
    <div 
        className='d-flex flex-md-column flex-row gap-2 align-items-center align-items-md-start'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",  
            backdropFilter: "blur(3px)",             
            border: "none", 
            borderRadius: "15px",                        
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",  
            height: "fit-content",
            padding: "20px",
            width:isMobile && "100%"

          }}
    >

      <RiUserSettingsLine size={60} color='#ffffff'/>
      <div className='s16 w4 text-white '>
      Direct Contact <br/> with Manufacturers
      </div>

    </div>




    <div 
        className='d-flex flex-md-column flex-row gap-2 align-items-center align-items-md-start'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",  
            backdropFilter: "blur(3px)",             
            border: "none", 
            borderRadius: "15px",                        
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",  
            height: "fit-content",
            padding: "20px",
            width:isMobile && "100%"

          }}
    >

      <MdOutlineAddBusiness size={60} color='#ffffff'/>
      <div className='s16 w4 text-white '>
      Millions of <br/> Businesses Offering
      </div>

    </div>



    <div 
        className='d-flex flex-md-column flex-row gap-2 align-items-center align-items-md-start'
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.2)",  
            backdropFilter: "blur(3px)",             
            border: "none", 
            borderRadius: "15px",                        
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",  
            height: "fit-content",
            padding: "20px",
            width:isMobile && "100%" 
          }}
    >

      <RiUserSettingsLine size={60} color='#ffffff'/>
      <div className='s16 w4 text-white '>
      Direct Contact <br/> with Manufacturers
      </div>

    </div>
    </div>
  )
}

export default HomeTrans