import React from 'react'
import more1 from "../../../Assets/image/viewmore1.png"
import more2 from "../../../Assets/image/viewmore2.png"
import more3 from "../../../Assets/image/viewmore3.png"
import { Img } from 'react-image';
import { Skeleton } from '@mui/material';

const ViewMoreCard = () => {

    return (
        <div className='container-fluid'>
            <div className='row'>

                <div className='col-6 p-1'>
                    <Img
                        loader={<Skeleton variant="square" />}
                        src={more1}
                        unloader={<div className='center-data' style={{
                            height: "50px",
                            width: "100%",
                            backgroundColor: "#f0f0f0"
                        }}>
                            <p>more1</p>
                        </div>}
                        style={{ height: "50px", width: "100%", objectFit: "cover", borderRadius: "5px" }}
                    />

                </div>

                <div className='col-6 p-1'>
                    <Img
                        loader={<Skeleton variant="square" />}
                        src={more2}
                        unloader={<div className='center-data' style={{
                            height: "50px",
                            width: "100%",
                            backgroundColor: "#f0f0f0"
                        }}>
                            <p>more2</p>
                        </div>}
                        style={{ height: "50px", width: "100%", objectFit: "cover", borderRadius: "5px" }}
                    />

                </div>


                <div className='col-5 p-1'>
                    <Img
                        loader={<Skeleton variant="square" />}
                        src={more3}
                        unloader={<div className='center-data' style={{
                            height: "50px",
                            width: "100%",
                            backgroundColor: "#f0f0f0"
                        }}>
                            <p>more3</p>
                        </div>}
                        style={{ height: "50px", width: "100%", objectFit: "cover", borderRadius: "5px" }}
                    />

                </div>

                <div className='col-7 p-1'>
                    <div className='rounded text-truncate border h-100 w-100 center-data s14 w5'>
                        +1234
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ViewMoreCard