import React, { useState, useEffect } from 'react'; 
import translate from 'translate';

translate.engine = "google"; 
translate.key = process.env.GOOGLE_KEY; 

export const transPlaceHolder =  async (text) => {
  let language = localStorage.getItem("language");  
  language = language ? language : 'en'; 
  return await translate(text, language);
} 

const Span = (props) => { 
  const [originalText, setOriginalText] = useState(props.children);
  const [translatedText, setTranslatedText] = useState(props.children);

  useEffect(() => {   
    const translateText = async () => { 
      if (originalText || originalText === 0) { 
        let language = localStorage.getItem("language");  
        language = language ? language : 'en'; 
        translate(originalText, language).then(result => setTranslatedText(result));
      }
    };

    translateText();  
  }, [originalText]);  

  useEffect(() => {
    setOriginalText(props.children);
  }, [props.children]);

  return (
    <span {...props}>{translatedText}</span>  
  );
};

export default Span;