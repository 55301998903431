import { countries } from 'country-data';

export function getCountryFlagSrc(countryCode) {
    const baseUrl = "https://flagcdn.com/h20/"; 
    return `${baseUrl}${countryCode?.toLowerCase()}.png`;
}

export function getCountryName(countryCode) {
    const country = countries[countryCode?.toUpperCase()];
    return country ? country.name : "Unknown Country";
}
